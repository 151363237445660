import { graphql, PageProps } from 'gatsby';
import React from 'react';

import ArticleList from '../components/article-list';
import Layout from '../components/layout';
import SEO from '../components/seo';

export type PageContext = {
  tag: string;
  limit: number;
  skip: number;
  basePath: string;
  numPages: number;
  currentPage: number;
};

type Props = PageProps<GatsbyTypes.TagBlogListQuery, PageContext>;

const TagBlogList: React.FC<Props> = ({ data, pageContext }) => {
  const siteMetadata = data?.site?.siteMetadata;
  const siteTitle = siteMetadata?.title || '';
  const siteDescription = siteMetadata?.description || '';
  const posts = data.allMarkdownRemark.edges.map(edge => edge.node);
  const { tag, basePath, currentPage, numPages } = pageContext;
  const headTitle = currentPage === 1 ? tag : `${tag} ${currentPage}ページ目`;

  return (
    <Layout title={siteTitle} description={siteDescription}>
      <SEO title={headTitle} />
      <ArticleList posts={posts} baseUrl={basePath} currentPage={currentPage} numPages={numPages} />
    </Layout>
  );
};

export default TagBlogList;

export const pageQuery = graphql`
  query TagBlogList($tag: String!, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY年M月D日")
            updatedAt(formatString: "YYYY年M月D日")
            title
            description
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 320, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredSVG
            tags
          }
        }
      }
    }
  }
`;
